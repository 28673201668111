import React from 'react';
import styled from 'styled-components/macro';
import { keyframes } from 'styled-components/macro';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/pro-solid-svg-icons';

import { usePlayboyLandingPage } from 'src/client/helpers/usePlayboyLandingPage';
import { destructUUID } from '@tovia/man-app-utils/lib/helpers/uuids';
import { useSelector } from 'src/client/redux/modules/helpers/useSelector';

type Props = {
  title?: string;
  message?: string;
};

const IntroWrapper = styled.div`
  align-items: center;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  position: relative;
  margin-bottom: 6px;
  overflow-x: clip;
  width: 100%;
`;

const BackgroundVideo = styled.video`
  position: relative;
  width: 100%;
  height: 100%;
  z-index: -1;
  @media (max-width: 1024px) and (orientation: portrait) {
    width: auto;
  }
`;

const IntroMessage = styled.div`
  flex-basis: 100%;
  width: 100%;
  max-width: 720px;
  text-align: center;
  margin-top: -12%;
  position: absolute;
  @media (max-width: 1024px) {
    max-width: 520px;
  }
  @media (max-width: 430px) {
    max-width: 300px;
  }
`;

const IntroTitle = styled.h1`
  color: #fff;
  font-size: 72px;
  line-height: 1.2;
  font-family: Urbanist, 'Open Sans', sans-serif;
  font-weight: 800;
  margin: 0 auto 30px;
  text-align: center;
  @media (max-width: 1024px) {
    font-size: 52px;
    margin-bottom: 24px;
  }
  @media (max-width: 430px) {
    font-size: 32px;
    margin-bottom: 15px;
  }
  @media (max-height: 415px) {
    margin-top: 20px;
  }
`;

const IntroDescription = styled.span`
  display: block;
  color: #fff;
  font-family: Urbanist, 'Open Sans', sans-serif;
  font-weight: 700;
  font-size: 20px;
  line-height: 1.2;
  text-align: center;
  @media (max-width: 1024px) {
    font-size: 18px;
  }
  @media (max-width: 430px) {
    font-size: 16px;
  }
`;

const IntroPlayboyDescription = styled(IntroDescription)`
  margin: 20px auto;
`;

const IntroArrow = styled.div`
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
  text-align: center;
`;

const IntroRibbon = styled.img`
  position: absolute;
  right: 0;
  top: 0;
  text-align: center;
`;

const IntroLogo = styled.img`
  max-width: 100%;
  text-align: center;
  @media (max-height: 768px) {
    max-width: 40%;
  }
`;

const IntroArrowMessage = styled.span`
  color: #fff;
  font-family: Urbanist, 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 1;
  display: block;
  @media (max-width: 1024px) {
    font-size: 14px;
  }
`;

const breatheAnimation = keyframes`
 0% { top: -5px; }
 50% { top: 5px; }
 100% { top: -5px; }
`;

const IntroArrowDown = styled.span`
  color: #fff;
  font-size: 30px;
  display: block;
  position: relative;
  animation-name: ${breatheAnimation};
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
`;

export const Intro = (props: Props) => {
  const { cdnUrl } = useSelector((state) => state.app);
  const site = useSelector((state) => state.site);

  const showPlayboyBanner = usePlayboyLandingPage();

  return (
    <IntroWrapper>
      {showPlayboyBanner && (
        <IntroRibbon src={`${cdnUrl}/splash/${destructUUID(site.networkUUID)}/playboy_offer.png`} />
      )}
      <BackgroundVideoSwitcher />
      <IntroMessage>
        <IntroTitle>{props.title}</IntroTitle>
        <IntroDescription>{props.message}</IntroDescription>
        {showPlayboyBanner && (
          <>
            <IntroPlayboyDescription>Brought to you by</IntroPlayboyDescription>
            <IntroLogo src={`${cdnUrl}/splash/${destructUUID(site.networkUUID)}/playboy_logos.png`} />
          </>
        )}
      </IntroMessage>
      <IntroArrow>
        <IntroArrowMessage>Scroll for more</IntroArrowMessage>
        <IntroArrowDown>
          <FontAwesomeIcon icon={faAngleDown} />
        </IntroArrowDown>
      </IntroArrow>
    </IntroWrapper>
  );
};

const BackgroundVideoSwitcher = () => {
  const { contentCdnUrl, showSFWSite } = useSelector((state) => state.app);
  const site = useSelector((state) => state.site);

  if (showSFWSite) {
    return (
      <BackgroundVideo autoPlay loop muted playsInline>
        <source src={`${contentCdnUrl}/nonnude/background.mp4`} type="video/mp4" />
      </BackgroundVideo>
    );
  }

  return (
    <BackgroundVideo autoPlay loop muted playsInline>
      <source
        src={`${contentCdnUrl}/${site.UUID}/media/splash/desktop-hero.mp4`}
        // Safari >17.5 has a bug looping .webm, the codec here should only work in Safari
        type={'video/mp4; codecs="hvc1"'}
        media="(min-width: 768px)"
      />
      <source
        src={`${contentCdnUrl}/${site.UUID}/media/splash/desktop-hero.webm`}
        type="video/webm"
        media="(min-width: 768px)"
      />
      <source
        src={`${contentCdnUrl}/${site.UUID}/media/splash/desktop-hero.mp4`}
        type="video/mp4"
        media="(min-width: 768px)"
      />
      <source
        src={`${contentCdnUrl}/${site.UUID}/media/splash/mobile-hero.mp4`}
        type="video/mp4"
        media="(max-width: 768px)"
      />
    </BackgroundVideo>
  );
};
