import { SfwFlags } from '@tovia/man-protos/dist/types/content.types';
import { useSelector } from 'src/client/redux';
import { useCallback } from 'react';

type ItemAttributes = {
  clean?: boolean;
  thumbnail?: boolean;
  wide?: boolean;
};

type Item = {
  coverCleanImagePath?: string;
  coverCleanImagePathSfw?: string;
  coverImagePath?: string;
  coverImagePathSfw?: string;
  sfwFlags?: SfwFlags;
  siteUUID: string;
  thumbnailCoverPath: string;
  thumbnailCoverPathSfw?: string;
};

const getSfwCoverPath = (item: Item, itemAttributes?: ItemAttributes) => {
  const { hasSfwWideCover, hasSfwPortraitCover, hasSfwCleanCover, hasSfwWideCleanCover } = item.sfwFlags || {};
  const { clean = false, thumbnail = false, wide = false } = itemAttributes || {};
  let coverPath = '';

  if (hasSfwPortraitCover) {
    coverPath = item.coverImagePathSfw || '';
  }
  if (wide && clean && hasSfwWideCleanCover) {
    coverPath = item.coverCleanImagePathSfw?.replace('t_cover_', 'wide_') || '';
  }
  if (clean && !wide && hasSfwCleanCover) {
    coverPath = item.coverCleanImagePathSfw || '';
  }
  if (wide && !clean && hasSfwWideCover) {
    coverPath = coverPath.replace('t_cover_', 'wide_') || '';
  }
  if (thumbnail && hasSfwPortraitCover) {
    coverPath = item.thumbnailCoverPathSfw || '';
  }

  return coverPath;
};

const getGalleryCoverUrl = (contentCdnUrl: string, item: Item, itemAttributes?: ItemAttributes, sfw?: boolean) => {
  const { clean = false, thumbnail = false, wide = false } = itemAttributes || {};
  // const site = SITES_OBJ[item.siteUUID];
  let coverPath = item.coverImagePath || '';
  if (clean) {
    coverPath = item.coverCleanImagePath || '';
  }
  if (thumbnail) {
    coverPath = item.thumbnailCoverPath;
  }
  if (wide) {
    coverPath = coverPath.replace('t_cover_', 'wide_');
  }

  let canShowSfw = false;
  if (sfw) {
    const sfwCoverPath = getSfwCoverPath(item, itemAttributes);
    canShowSfw = Boolean(sfwCoverPath);
    coverPath = canShowSfw ? sfwCoverPath : coverPath;
  }

  return { url: `${contentCdnUrl}/${item.siteUUID}${coverPath}`, canShowSfw };
};

export const useGetGalleryCoverUrl = () => {
  const sfwEnabled = useSelector((state) => state.app.showSFWSite);

  return useCallback(
    (
      contentCdnUrl: string,
      item: {
        coverImagePath?: string;
        coverImagePathSfw?: string;
        coverCleanImagePathSfw?: string;
        coverCleanImagePath?: string;
        thumbnailCoverPath: string;
        thumbnailCoverPathSfw?: string;
        sfwFlags?: SfwFlags;
        siteUUID: string;
      },
      itemAttributes?: ItemAttributes,
    ) => {
      return getGalleryCoverUrl(contentCdnUrl, item, itemAttributes, sfwEnabled);
    },
    [sfwEnabled],
  );
};
